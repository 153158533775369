(function (w, d, s, o, f, js, fjs) {
  w['JS-Widget'] = o;
  w[o] =
    w[o] ||
    function () {
      (w[o].q = w[o].q || []).push(arguments);
    };
  (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
  js.id = o;
  js.src = f;
  js.async = 1;
  fjs.parentNode.insertBefore(js, fjs);
})(window, document, 'script', 'mw', 'https://cdn.us.document360.io/static/js/widget.js');
mw('init', {
  apiKey:
    '6u5guwqLsbNPn8cqFNzEjt05z0+fbBHcy+0wMbGFwKWcKDXHjC/mSxeJu5G3S2cXzlQJpw2JcUGHCLUb47m7CyuBVYgTuN2dtPvHMX3EaV6V2yHm/iDO7fDgmvGY9MuCM7gOQ6vFCWhfoD7mawc9+g=='
});
